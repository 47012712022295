import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import 'firebase/messaging';
// import { config } from '../config'

const prodConfig = {
    apiKey: 'AIzaSyCKK1IA0tSg1Fg0foAc3N8wwHMvTHl_Yj4',
    authDomain: 'leasera-200719.firebaseapp.com',
    databaseURL: 'https://leasera-200719.firebaseio.com',
    projectId: 'leasera-200719',
    storageBucket: 'leasera-200719.appspot.com',
    messagingSenderId: '662469587140',
    appId: "1:662469587140:web:63efd01be202870886d991"
  }
const app = firebase.initializeApp(prodConfig)

export const refresh = firebase.auth()


export const auth = app.auth()


export default app